<script>
import moment from "moment";
import localforage from "localforage";
import {mapState} from "vuex";
import {FOODS_LIST} from "@utils/localForageKeys";

const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

const microNutrientFields = ['saturatedFats', 'monounsaturatedFats', 'polyunsaturatedFats', 'cholesterol',
  'monodisaccharides', 'polysaccharides', 'dietaryFibers', 'water', 'alcohol', 'natrium',
  'potassium', 'calcium', 'magnesium', 'phosphorus', 'iron', 'zinc', 'retinol', 'carotene',
  'thiamine', 'riboflavin', 'vitaminB6', 'vitaminC', 'sugars', 'vitaminA',
  'caroteneA', 'caroteneB', 'cryptoxanthin', 'lycopene', 'lutein_zeaxanthin', 'niacin',
  'folicAcid', 'folateFood', 'folateDFE', 'folateTotal', 'choline', 'vitaminB12', 'vitaminB12Added',
  'vitaminD', 'vitaminE', 'vitaminK', 'copper', 'selenium', 'caffeine', 'theobromine'
];

export default {

  computed: {
    ...mapState({
      foodMeasures: state => state.food._foodMeasures,
    })
  },
  data() {
    return {
      isEducational: !!process.env.VUE_APP_IS_EDUCATIONAL,
      isOfflineVersion: process.env.VUE_APP_IS_LOCAL_INSTALLATION,
      newEvent: {
        date: null,
        startTime: null,
        endTime: null,
        duration: 30,
        endDate: null,
        notes: null,
        type: 1,
        title: '',
        selectedAthlete: null,
        sessionCost: 0,
        error: null,
        isSubmitting: false,
        existingClient: 1
      },
      dayNames: [
        this.$t('days.monday'),
        this.$t('days.tuesday'),
        this.$t('days.wednesday'),
        this.$t('days.thursday'),
        this.$t('days.friday'),
        this.$t('days.saturday'),
        this.$t('days.sunday')
      ],
      dayNamesShort: [
        this.$t('days.short.monday'),
        this.$t('days.short.tuesday'),
        this.$t('days.short.wednesday'),
        this.$t('days.short.thursday'),
        this.$t('days.short.friday'),
        this.$t('days.short.saturday'),
        this.$t('days.short.sunday')
      ],
      dayNamesAlt: [1, 2, 3, 4, 5, 6, 7].map(x => this.$t('nthChoice', {num: x})),
      dayMeals: [
        this.$t('meals.breakfast'),
        this.$t('meals.midBreakfast'),
        this.$t('meals.lunch'),
        this.$t('meals.afterLunch'),
        this.$t('meals.dinner'),
        this.$t('diet_plan.meals.5'),
        this.$t('diet_plan.meals.6'),
      ],
      elDatePicker: {
        days: [
          this.$t('days.short.sunday'),
          this.$t('days.short.monday'),
          this.$t('days.short.tuesday'),
          this.$t('days.short.wednesday'),
          this.$t('days.short.thursday'),
          this.$t('days.short.friday'),
          this.$t('days.short.saturday')
        ].map(x => x.replace('.', '')), // replace `.` to make it look beter
        months: months.map(x => this.$t('months.short.' + x)),
        pickers: [
          this.$t('datePickerPlaceholders.nextSeven'),
          this.$t('datePickerPlaceholders.nextThirty'),
          this.$t('datePickerPlaceholders.prevSeven'),
          this.$t('datePickerPlaceholders.prevThirty'),
        ],
        placeholder: {
          date: this.$t('datePickerPlaceholders.date'),
          dateRange: this.$t('datePickerPlaceholders.range'),
        }
      },
      quillOptions: {
        placeholder: this.$t('insertNotes'),
        modules: {
          toolbar: {
            container: [["bold", "italic"],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              [{'color': []}, {'background': []}],
              ['link']
            ],
          },
        }
      },
      microNutrientFields,
      bothCategoriesMicronutrients: ['saturatedFats', 'monounsaturatedFats', 'polyunsaturatedFats', 'cholesterol', 'dietaryFibers', 'water', 'alcohol', 'natrium',
        'potassium', 'calcium', 'magnesium', 'phosphorus', 'iron', 'zinc', 'retinol',
        'thiamine', 'riboflavin', 'vitaminB6', 'vitaminC'],
      trixopoulouMicronutrients: ['monodisaccharides', 'polysaccharides', 'carotene'],
      usdaMicronutrients: ['sugars', 'vitaminA',
        'caroteneA', 'caroteneB', 'cryptoxanthin', 'lycopene', 'lutein_zeaxanthin', 'niacin',
        'folicAcid', 'folateFood', 'folateDFE', 'folateTotal', 'choline', 'vitaminB12', 'vitaminB12Added',
        'vitaminD', 'vitaminE', 'vitaminK', 'copper', 'selenium', 'caffeine', 'theobromine'],
      microNutrientTranslation: microNutrientFields.reduce((acc, val) =>{
        acc[val] = this.$t(`dietPlans.microTranslation.${val}`);
        return acc;
      } , {}),
    }
  },

  methods: {
    formatDriObject(gender, age) {
      let fixedGender = gender === 'M' ? 'male' : 'female';
      let fixedAge = null;

      let ageRanges = ['7–12', '1–3', '4–8', '9–13', '14–18', '19–30', '31–50', '51–70', '71'];

      if (age < 9) fixedGender = 'children'
      else if (age < 1) fixedGender = 'infants'

      if (age < 1) {
        fixedAge = ageRanges[0]
      } else if (age < 4) {
        fixedAge = ageRanges[1]
      } else if (age < 9) {
        fixedAge = ageRanges[2]
      } else if (age < 14) {
        fixedAge = ageRanges[3]
      } else if (age < 19) {
        fixedAge = ageRanges[4]
      } else if (age < 31) {
        fixedAge = ageRanges[5]
      } else if (age < 51) {
        fixedAge = ageRanges[6]
      } else {
        fixedAge = ageRanges[7]
      }

      return {
        age: fixedAge,
        gender: fixedGender
      }
    },
    formatName(mealFood) {

      if (mealFood['mealFoods.id']) {
        if (mealFood['mealFoods.hasAltName']) return mealFood['mealFoods.altName'];
        else return mealFood.name
      }
      if (mealFood.mealFoods) {
        return mealFood.mealFoods.hasAltName ? mealFood.mealFoods.altName : mealFood.food.name;
      } else {
        return mealFood.hasAltName ? mealFood.altName : mealFood.food.name;
      }
    },
    formatFoodUnits(food) {
      let print = Number.isInteger(food.quantity) ? food.quantity : food.quantity.toFixed(1);
      let key = print;

      // check if measurementUnit ID isn't grammars, if so then change key to greek letters instead of 0.5
      if (food.measurementUnit !== 8) {
        print = this.replaceFoodUnits(key);
      }
      let measure = this.foodMeasures.find(x => x.id === food.measurementUnit);
      return measure && measure.displayName ? print + ' ' + measure.displayName : print;
    },
    getDate(date) {
      if (date) return moment(date).format('DD-MM-YYYY');
      return moment().format('DD-MM-YYYY');
    },

    replaceFoodUnits: unitValue => {
      unitValue = parseFloat(unitValue);
      if (unitValue === 0.25) return '1/4';
      if (unitValue === 0.33) return '1/3';
      if (unitValue === 0.5) return '1/2';
      if (unitValue === 0.66) return '2/3';
      if (unitValue === 0.75) return '3/4';

      return unitValue;
    },

    replaceCommaWithDot: value => {
      return value.replace(",", ".");
    },

    formatDate: value => {
      return moment(value).format('DD/MM/YYYY HH:mm');
    },

    formatDateWithoutHour: value => {
      return moment(value).format('DD/MM/YYYY');
    },

    formatDateWithoutHourAsLink: value => {
      return moment(value).format('DD-MM-YYYY');
    },

    triggerActiveAthletesModal(show = true) {
      this.$store.commit('athlete/SET_SHOW_ACTIVE_ATHLETES', show);
    },
    async fetchFoods() {
      let foods = await localforage.getItem(FOODS_LIST);

      if (localStorage.getItem('food.foodUpdateRequired')) {
        await this.$store.dispatch('food/fetchCacheFoods');
        localStorage.removeItem('food.foodUpdateRequired');
      }
      if (!foods || foods.length === 0) {
        return this.$store.dispatch('food/fetchCacheFoods')
      } else {
        return this.$store.dispatch('food/refreshCacheFoods');
      }
    },

    async fetchFoodMeasures() {
      if (!this.foodMeasures) {
        await this.$store.dispatch('food/fetchCacheFoodMeasures')
      } else {
        await this.$store.dispatch('food/refreshCacheFoodMeasures');
      }
    },

  }
}
</script>

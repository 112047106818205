export default{

    title: 'Something went wrong',
    errorPageText: 'Something went wrong, either with the software or your internet connection. Please try again later!',
    update: 'Update failed. Please try again',

    text: 'This action failed. Please try again. If the issue persists, please contact us at info@athlisis.com!',
    sharedQuestionnaireIncorrectParams: 'You have used an invalid link or the link is no longer active. Please contact your dietitian!',
    rateLimitEmail: 'To prevent spam, everyone is limited to 1 email per 30 seconds.',
    NO_MEAL_TYPE_TEMPLATES: 'You need to have at least 1 template for each selected mela category (breakfast, snacks, lunch, dinner...)',

    notFound: 'Not found',
    loadingAthletes: 'Failed to load clients. Please refresh the page!',
    pageNotFound: 'PAGE NOT FOUND!',
    pageNotFoundDesc: 'The requested page was not found. If you think this page should exist, please contact us at info@athlisis.com.',

    googleCalendarLoad: 'Google calendar data were not loaded successfully.',
    syncStopFailed: 'Stopping sync failed. Please try again.',
    calendar: 'Failed to load calendar. Please try again.',
    safari: 'Please follow the instructions to properly print on Safari.',
    foodRemoved: 'Failed to remove food. Please try again!',

    emailAlreadyInUse: 'This email is already in use.',
    viewInNewTab: 'Click here to view the client.',
}

export default{

    title: 'Κάτι πήγε λάθος',
    errorPageText: 'Κάτι πήγε λάθος είτε με την εφαρμογή, είτε με το Internet σας. Παρακαλώ δοκιμάστε ξανά σε λίγο!',
    update: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά',

    text: 'Η ενέργεια απέτυχε. Παρακαλώ προσπαθήστε ξανά αργότερα. Εάν το πρόβλημα επιμένει, επικοινωνήστε μαζί μας στο info@athlisis.com!',
    sharedQuestionnaireIncorrectParams: 'Δεν έχετε χρησιμοποιήσει σωστό σύνδεσμο ή ο σύνδεσμος που χρησιμοποιείται είναι πλέον ανενεργός. Επικοινωνήστε με το διατροφολόγο σας!',
    rateLimitEmail:'Για λόγους ασφάλειας και αποφυγή spam, να στέλνετε το πολύ 1 e-mail ανά 30 δευτερόλεπτα.',
    NO_MEAL_TYPE_TEMPLATES: 'Θα πρέπει έχετε τουλάχιστον 1 πρότυπο γεύμα σε κάθε επιλεγμένη κατηγορία γεύματος (πρωινό, δεκατιανό, κ.λπ.).',

    notFound: 'Δεν βρέθηκε',
    loadingAthletes: 'Η φόρτωση των αθλητών απέτυχε. Δοκιμάστε ξανά!',
    pageNotFound: 'Η ΣΕΛΙΔΑ ΔΕΝ ΒΡΕΘΗΚΕ!',
    pageNotFoundDesc: 'Η σελίδα που αναζητήσατε δεν βρέθηκε. Εάν πιστεύετε ότι η σελίδα θα έπρεπε να υπάρχει, ενημερώστε μας με email στο issues@athlisis.com.',

    googleCalendarLoad: 'Η κατάσταση του Google Calendar δεν φορτώθηκε σωστά.',
    syncStopFailed: 'Η διακοπή συγχρονισμού απέτυχε. Παρακαλώ δοκιμάστε ξανά.',
    calendar: 'Η φόρτωση του ημερολογίου απέτυχε. Παρακαλώ δοκιμάστε ξανά.',

    safari: 'Παρακαλώ ακολουθήστε τις οδηγίες που υπάρχουν για σωστή εκτύπωση στο Safari.',
    foodRemoved: 'Η αφαίρεση του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!',

    emailAlreadyInUse: 'Αυτό το e-mail χρησιμοποιείται ήδη.',
    viewInNewTab: 'Κάντε κλικ εδώ για να δείτε την καρτέλα του πελάτη.',
}


import axios from 'axios';
import {axiosFailInterceptor} from "@router";

/**
 * A Helper to get data from CMS using a non-authenticated instance of axios.
 */
export const getFromCms = async (url, params = {}) => {
    const instance = axios.create();

    return instance.get(url,{params, transformRequest: (data, headers) => {
            delete headers['x-dietitian-token'];
            delete headers['x-access-token'];
        }})
}

export const generateAxiosRequestKey = (url, params, data) => {
    const stringifiedData = typeof data === 'string' ? data : JSON.stringify(data || {});
    return `${url}:${JSON.stringify(params || {})}:${stringifiedData}`;
};

const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_API });
const pendingRequests = new Map();

// axios pending requests so we can avoid doing the exact same query at the same moment.
axiosInstance.interceptors.response.use(
    (res) =>  {
        const requestKey = generateAxiosRequestKey(res.config.url, res.config.params, res.config.data);
        pendingRequests.delete(requestKey); // Cleanup after response

        return res
    },
    (fail) => {
        const requestKey = generateAxiosRequestKey(fail.config.url, fail.config.params, fail.config.data);
        pendingRequests.delete(requestKey);
        return axiosFailInterceptor(fail)
    }
);

// deduplicate axios.
axiosInstance.interceptors.request.use((config) => axiosDeduplicationFunction(pendingRequests, config));

export const axiosDeduplicationFunction = (pendingRequests, config) => {
    const requestKey = generateAxiosRequestKey(config.url, config.params, config.data);
    // If the request is already pending, return a promise that resolves the same
    if (pendingRequests.has(requestKey)) return new Promise(() => {});
     // Return an empty promise to block the second request

    // Track the ongoing request
    const cancelPromise = axios.CancelToken.source();
    config.cancelToken = cancelPromise.token;
    pendingRequests.set(requestKey, cancelPromise);

    return config;
}

export const getCoreAxiosInstance = () => {
    return axiosInstance;
}

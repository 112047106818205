<template>
  <div id="athlete-list-layout">
    <searchBar>
      <template v-slot:forms>
        <form id="athlete-options-form" class="form-inline d-flex flex-wrap gap-3 tw-items-center">
<!--          <div class="text-lg-right d-flex flex-wrap gap-3">-->
            <b-button v-b-tooltip.hover variant="light"
                      :class="options.athletes.view === 'table' ? 'active': ''" :title="$t('pages.athletes.viewModes.table')"
                      class="layout-switch" @click="changeLayoutMode('type','table')">
              <i class="fe-list"></i>
            </b-button>

            <b-button v-b-tooltip.hover variant="light"
                      :class="options.athletes.view === 'card' ? 'active': ''" :title="$t('pages.athletes.viewModes.cards')"
                      class="layout-switch" @click="changeLayoutMode('type', 'card')">
              <i class="fe-grid"></i>
            </b-button>

            <b-form-select id="select-athletes-per-page" v-model="api.query.limit" class="custom-select max-md:tw-w-full"
                           @change="changeLayoutMode('perPage', api.query.limit)">
              <b-form-select-option value='6'>6</b-form-select-option>
              <b-form-select-option value='12'>12</b-form-select-option>
              <b-form-select-option value='24'>24</b-form-select-option>
              <b-form-select-option value='36'>36</b-form-select-option>
            </b-form-select>

            <b-form-select id="select-athletes-per-page" v-model="api.query.sorting" class="custom-select"
                           @change="changeLayoutMode('sorting', api.query.sorting)">
              <b-form-select-option value='createdAt:DESC'>Ημ. δημιουργίας (νεότερο -> παλαιότερο)</b-form-select-option>
              <b-form-select-option value='createdAt:ASC'>Ημ. δημιουργίας (παλαιαότερο -> νεότερο)</b-form-select-option>
              <b-form-select-option value='firstName:ASC'>Όνομα (α -> ω)</b-form-select-option>
              <b-form-select-option value='firstName:DESC'>Όνομα (ω -> α)</b-form-select-option>
              <b-form-select-option value='lastName:ASC'>Επώνυμο (α -> ω)</b-form-select-option>
              <b-form-select-option value='lastName:DESC'>Επώνυμο (ω -> α)</b-form-select-option>
            </b-form-select>

<!--          </div>-->
          <div class="form-group max-md:tw-w-full"><label class="sr-only">{{ $t('search') }}</label>
            <b-form-input v-model="api.filters.search" type="search" class="form-control" :placeholder="$t('searchPlaceholder')" debounce="400" />
          </div>
          <div class="form-group max-md:tw-w-full">
            <b-button v-if="athleteTags.length > 0" variant="light" class="max-md:tw-w-full" @click="showTagList = !showTagList">
              {{$t('global.filterByTag.title')}} <i v-if="!showTagList" class="fe-arrow-down"></i> <i v-if="showTagList" class="fe-arrow-up"></i>
            </b-button>
          </div>
        </form>
      </template>
      <template v-slot:buttons class="max-md:tw-order-1">
        <div class="text-lg-right mt-3 mt-lg-0 d-flex flex-wrap gap-3 tw-items-center justify-content-end">
          <b-button variant="light" @click="$router.push('/athletes/statistics')">
            <i class="icon-pie-chart"></i>
          </b-button>
          <b-dropdown id="dropdown-dropleft" dropleft variant="light">
            <template v-slot:button-content>
              <i class="fe-settings"></i>
            </template>
            <b-dropdown-text><h5 style="font-weight:800;"><i class="fe-settings"></i> {{ $t('global.options') }}:</h5> </b-dropdown-text>
            <b-dropdown-item @click="$router.push({ name: 'user-tags' })"><span class="fe-tag" /> {{ $t('pages.athletes.options.editTags') }}</b-dropdown-item>
            <b-dropdown-item @click="$router.push({ name: 'questionnaires' })"><span class="fe-help-circle"/> {{ $t('pages.athletes.options.editQuestionnaires') }}</b-dropdown-item>
            <b-dropdown-item @click="openCSVImportModal"><span class="fe-file"/> {{ $t('pages.athletes.options.massImport') }}</b-dropdown-item>
            <b-dropdown-item @click="exportClients" :disabled="isExporting"><span class="fe-file-text"/> {{ $t('pages.athletes.options.massExport') }}</b-dropdown-item>
          </b-dropdown>
          <button type="button" class="btn btn-primary mr-1" @click="$router.push('/athletes/add')" :disabled="isEducational && api.count >= 10">
            {{ $t('routes.add') }}</button>
        </div>
      </template>
    </searchBar>

    <div v-if="showTagList" class="choose-from-tags row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-button variant="outline-primary" class="tag-btn" :class=" (null === api.filters.tagId) ? 'active-tag-btn' : '' "
                      @click="fetchPlansByTag(null)"> {{$t('global.viewAll') }}</b-button>
            <span v-for="tag in athleteTags" :key="tag.id">
                      <b-button variant="outline-primary" class="tag-btn" :class=" (tag.id === api.filters.tagId) ? 'active-tag-btn' : '' "
                                @click="fetchPlansByTag(tag.id)"><i class="fe-tag"></i> {{tag.text}}</b-button>
                  </span>
          </div>
        </div>
      </div>
    </div>

    <b-alert show variant="warning" v-if="isEducational && api.count >= 10">{{ $t('educational.customerLimit') }}</b-alert>

    <Empty
        v-if="(api.rows.length === 0 && !api.filters.tagId && !api.loading && api.filters.search === '' && api.page === 1)"
        :title="$t('pages.athletes.empty.title')"
        :subtitle="$t('pages.athletes.empty.subtitle')"
        :btn-text="$t('pages.athletes.empty.addFirst')"
        @click="$router.push({ name: 'add-athlete' })"
    ></Empty>

    <div v-else class="row" :class="options.athletes.view === 'card' ? 'card-wrapper' : 'athlete-table-wrapper'">

      <div v-for="athlete of api.rows" :key="athlete.id" :class="options.athletes.view === 'card' ? 'col-lg-4' : 'col-lg-12'">
        <div class="text-center card-box athlete-box" @click="$router.push(`/athletes/${athlete.id}`)">
          <div v-if="options.athletes.view === 'card'" class="pt-2 pb-2">
            <img class="rounded-circle" style="width:80px;height:80px;" :src="athlete.image || 'https://www.gravatar.com/avatar/1db7993cb4a4a74ce58f4a6926cec867?s=80&d=mp&r=g'"/>
            <h4 class="mt-3"><router-link tag="a" :to="`/athletes/${athlete.id}`" class="text-primary">
              {{ formatAthleteName(athlete) }}
            </router-link></h4>
            <p class="text-muted"><span v-if="athlete.mobileNum"><a :href="'tel:' + athlete.mobileNum" class="text-primary-muted">{{athlete.mobileNum}}</a></span><span v-else>-</span></p>
            <div class="row mt-2">
              <div class="col-4">
                <div class="mt-3">
                  <h4>{{athlete.dietPlanCount}}</h4>
                  <p class="mb-0 text-muted text-truncate">{{ $t('pages.athletes.diets') }}</p>
                </div>
              </div>
              <div class="col-4">
                <div class="mt-3">
                  <h4>{{athlete.measurementCount}}</h4>
                  <p class="mb-0 text-muted text-truncate">{{ $t('pages.athletes.measures') }}</p>
                </div>
              </div>
              <div class="col-4">
                <div class="mt-3">
                  <h4>{{athlete.finishedToDos}}/{{athlete.totalToDos}}</h4>
                  <p class="mb-0 text-muted text-truncate">{{ $t('pages.athletes.goals') }}</p>
                </div>
              </div>
            </div>

            <!-- end row-->
          </div>

          <div v-else class="">
            <div class="row align-items-center">
              <div class="col-md-1 avatar-col">
                  <span class="dp-name">
                     <img class="usr-image rounded-circle":src="athlete.image || 'https://www.gravatar.com/avatar/1db7993cb4a4a74ce58f4a6926cec867?s=80&d=mp&r=g'"/></span>
              </div>
              <div class="col-md-2 text-lg-left">
                <span class="text-muted">{{ $t('forms.fields.name') }}</span>  <br>
                <span class="dp-name "> <router-link tag="a" :to="`/athletes/${athlete.id}`" class="text-dark">
                {{ formatAthleteName(athlete) }}
                  </router-link> </span>
              </div>
              <div class="col-md-2 text-lg-left">
                <span class="text-muted">{{ $t('forms.fields.tel') }}</span> <br>
                <span class="dp-name ">
                    <span v-if="athlete.mobileNum"><a :href="'tel:' + athlete.mobileNum" class="text-primary">{{athlete.mobileNum}}</a></span><span v-else>-</span>
                  </span>
              </div>
              <div class="col-md-2 text-lg-left">
                <span class="text-muted">{{ $t('forms.fields.email') }}</span>  <br>
                <span class="dp-name ">
                    <span v-if="athlete.email"><a :href="'mailto:' + athlete.email" class="text-primary">{{athlete.email}}</a></span><span v-else>-</span>
                  </span>
              </div>
              <div class="col-md-1 text-lg-left">
                <span class="text-muted">{{ $t('pages.athletes.diets') }}</span>  <br>
                <span class="dp-name ">
                    {{athlete.dietPlanCount}}
                   </span>

              </div>
              <div class="col-md-1 text-lg-left">
                <span class="text-muted">{{ $t('pages.athletes.measures') }}</span>  <br>
                <span class="dp-name ">
                    {{athlete.measurementCount}}
                   </span>

              </div>

              <div class="col-md-2 text-lg-left">
                <span class="text-muted">{{ $t('global.createdAt') }}</span>  <br>
                <span class="dp-name ">
                    {{formatDateWithoutHour(athlete.createdAt)}}
                  </span>
              </div>

            </div>
          </div>
          <!-- end .padding -->
        </div>
        <!-- end card-box-->
      </div>

      <pagination-row :api="api" @fetchData="getData"></pagination-row>
    </div>

    <b-modal ref="mass-import-customers-modal" id="mass-import-customers-modal" :title="$t('pages.athletes.options.massImport')" hide-footer>
      <mass-import-modal
          @imported="getData"
          @closeModal="$refs['mass-import-customers-modal'].hide()"/>
    </b-modal>
  </div>
</template>
<script>
import searchBar from '@components/searchBar'
import MassImportModal from "@components/modals/MassImportModal";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import Vue from "vue";
import PaginationRow from "@components/global/PaginationRow";
import Empty from "@components/global/Empty.vue";
export default {
    page(){
      return {
        title: this.$t('global.athletes')
      }
    },
    components: {Empty, PaginationRow, MassImportModal, searchBar },
    mixins: [PaginatedDataMixin],
    data() {
      return {
        contactData: [],
        submitted: false,
        athleteTags: [],
        showTagList: false,
        options: null,
        isExporting: false
      }
    },

    created() {
        this.api.url = "/athlete";
        this.api.setup = false;
        Vue.set(this.api.filters, 'tagId', null);

        this.options = localStorage.getItem('layoutOptions') ? JSON.parse(localStorage.getItem('layoutOptions')) : null;
        if (!this.options) {
          this.options = {athletes: {perPage: 6, view: 'card', sorting: 'createdAt:DESC'}}
          localStorage.setItem('layoutOptions', JSON.stringify(this.options));
        }
        // initialize limits based on options!
        this.api.query.limit = this.options.athletes.perPage;
        this.api.query.sorting = this.options.athletes.sorting || 'createdAt:DESC';

        if(this.$route.query.email){
          this.api.filters.search = this.$route.query.email;
        }
        if(this.$route.query.search){
          this.api.filters.search = this.$route.query.search;
        }
        this.api.setup = true;
        this.fetchUserAthleteTags();

    },
    methods: {
        formatAthleteName(athlete) {
          if (this.api.query.sorting && this.api.query.sorting.includes('lastName')) {
            return `${athlete.lastName} ${athlete.firstName}`;
          }

          return `${athlete.firstName} ${athlete.lastName}`;
        },
        redirectToAthlete(athleteId){
            this.$router.push({path: '/athletes/', params: {athleteId: athleteId}})
        },

        fetchUserAthleteTags(){
            this.$axios.get(`/users/athlete-tags`).then(result => {
                this.athleteTags = result.data;
            }).catch(e => {
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: this.$t('errors.text')});
            })
        },

        fetchPlansByTag(tagId){
          this.api.filters.tagId = tagId;
        },

        changeLayoutMode(type, value){
          if(type === 'type'){
            this.options.athletes.view = value;
          }else if(type === 'perPage') {
            this.options.athletes.perPage = parseInt(value);
            this.api.query.offset = 0;
            this.api.query.limit = parseInt(value)
            this.triggerDataFetch();
          } else if (type === 'sorting') {
            this.options.athletes.sorting = value;
            this.api.query.sorting = value;
          }
          // save to localStorage.
          localStorage.setItem('layoutOptions', JSON.stringify(this.options));
        },

        openCSVImportModal(){
          this.$refs['mass-import-customers-modal'].show();
        },

      exportClients(){
          this.isExporting = true;
          this.$axios.get(`/athlete/export-clients`).then( res => {
            let blob = new Blob(['\ufeff', res.data],{type: "text/csv;charset=utf-8;"});

            const filename = 'athlisis_customer_export'+(new Date()).toISOString()+'.csv';

            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(blob, filename);
            }else{
              let a = document.createElement("a"),
              url = URL.createObjectURL(blob);
              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }, 0);
            }
            this.isExporting = false;
          }).catch(e => {console.log(e);this.isExporting = false;})
      }
    },
}
</script>

<style lang="scss">
#athlete-options-form{

  @media(max-width:767px){
    display:block;
    .layout-switch{
      width:45%;
    }
    #select-athletes-per-page{
      width:100%;
      margin-left:0!important;
      margin-right:0!important;
    }
    .form-control{
      width:100%;
    }
  }
}
.choose-from-tags{
  .tag-btn{
    padding:0.3rem 0.6rem !important;
    margin-right: 7px;
    margin-bottom:7px;
  }
  .active-tag-btn{
    background-color:$color_primary;
    color:#fff;
  }
}
.card-wrapper{

  .athlete-box{
    margin:1em 0;
    padding:3em 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 40px #E8EDF1B3;
    border-radius: 19px;
    cursor:pointer;
    &:hover{
      box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.2);
      transform: scale(1.03);
    }
  }
}

.athlete-table-wrapper{
  margin-top:5px;
  .athlete-box {
    cursor: pointer;
    &:hover{
      box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.2);
      transform: scale(1.01);
    }
  }
  .card-box{
    padding:0.5em 1em;
    margin-bottom:3px;
  }
  .usr-image{
    width:30px;
  }
  .text-muted{
    font-weight:bold;
    color:#000;
    font-size:0.8em;
  }
  @media(max-width:767px){
    .col-md-1{
      &.avatar-col{
        width:100%;
      }
      margin-bottom:10px;
      width:50%;
    }
    .col-md-2{
      margin-bottom:10px;
      width:50%;
    }
  }
}

.layout-switch{
  background:transparent;
}
</style>
